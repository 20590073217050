const extraAutoItems = [
    {name:'Allspice',id:230},
    {name:'Almond Flavoring',id:231},
    {name:'Almond',id:232},
    {name:'Angelica Root',id:233},
    {name:'Anis',id:234},
    {name:'Anise',id:235},
    {name:'Apple',id:236},
    {name:'Apricot',id:237},
    {name:'Asafoetida',id:238},
    {name:'Banana Syrup',id:239},
    {name:'Banana',id:240},
    {name:'Basil',id:241},
    {name:'Beef Stock',id:242},
    {name:'Berries',id:243},
    {name:'Black Pepper',id:244},
    {name:'Blackberries',id:245},
    {name:'Blood Orange',id:246},
    {name:'Blueberries',id:247},
    {name:'Brown Sugar',id:248},
    {name:'Butter',id:249},
    {name:'Candy',id:250},
    {name:'Cantaloupe',id:251},
    {name:'Caramel Coloring',id:252},
    {name:'Caramel Sauce',id:253},
    {name:'Cardamom',id:254},
    {name:'Cayenne Pepper',id:255},
    {name:'Celery Salt',id:256},
    {name:'Celery',id:257},
    {name:'Cherries',id:258},
    {name:'Cherry Grenadine',id:259},
    {name:'Cherry',id:260},
    {name:'Chocolate Ice-cream',id:261},
    {name:'Chocolate Sauce',id:262},
    {name:'Chocolate Syrup',id:263},
    {name:'Chocolate',id:264},
    {name:'Cinnamon',id:265},
    {name:'Cloves',id:266},
    {name:'Cocktail Onion',id:267},
    {name:'Cocoa Powder',id:268},
    {name:'Coconut Cream',id:269},
    {name:'Coconut Milk',id:270},
    {name:'Coconut Syrup',id:271},
    {name:'Condensed Milk',id:272},
    {name:'Coriander',id:273},
    {name:'Corn Syrup',id:274},
    {name:'Cornstarch',id:275},
    {name:'Cranberries',id:276},
    {name:'Cream of Coconut',id:277},
    {name:'Cream',id:278},
    {name:'Creme Fraiche',id:279},
    {name:'Cucumber',id:280},
    {name:'Cumin Powder',id:281},
    {name:'Cumin Seed',id:282},
    {name:'Dark Chocolate',id:283},
    {name:'Dark Soy Sauce',id:284},
    {name:'Demerara Sugar',id:285},
    {name:'Dried Oregano',id:286},
    {name:'Egg White',id:287},
    {name:'Egg Yolk',id:288},
    {name:'Egg',id:289},
    {name:'Fennel Seeds',id:290},
    {name:'Figs',id:291},
    {name:'Flaked Almonds',id:292},
    {name:'Food Coloring',id:293},
    {name:'Fresh Basil',id:294},
    {name:'Fresh Mint',id:295},
    {name:'Fruit',id:296},
    {name:'Garlic Sauce',id:297},
    {name:'Ginger Syrup',id:298},
    {name:'Ginger',id:299},
    {name:'Glycerine',id:300},
    {name:'Granulated Sugar',id:301},
    {name:'Grapes',id:302},
    {name:'Green Olives',id:303},
    {name:'Grenadine',id:304},
    {name:'Ground Ginger',id:305},
    {name:'Habanero Peppers',id:306},
    {name:'Half-and-half',id:307},
    {name:'Heavy cream',id:308},
    {name:'Honey syrup',id:309},
    {name:'Honey',id:310},
    {name:'Hot Sauce',id:311},
    {name:'Ice-Cream',id:312},
    {name:'Ice',id:313},
    {name:'Jello',id:314},
    {name:'Jelly',id:315},
    {name:'Kiwi',id:316},
    {name:'Lavender',id:317},
    {name:'Lemon Peel',id:318},
    {name:'lemon',id:319},
    {name:'Licorice Root',id:320},
    {name:'Light Cream',id:321},
    {name:'Lime Peel',id:322},
    {name:'Lime',id:323},
    {name:'Mandarin',id:324},
    {name:'Mango',id:325},
    {name:'Maple syrup',id:326},
    {name:'Maraschino cherry juice',id:327},
    {name:'Maraschino Cherry',id:328},
    {name:'Marjoram leaves',id:329},
    {name:'Marshmallows',id:330},
    {name:'Mini-snickers bars',id:331},
    {name:'Mint syrup',id:332},
    {name:'Mint',id:333},
    {name:'Nutmeg',id:334},
    {name:'Olive Brine',id:335},
    {name:'Olive Oil',id:336},
    {name:'Olive',id:337},
    {name:'Onion',id:338},
    {name:'Orange Peel',id:339},
    {name:'Orange Slice',id:340},
    {name:'Orange spiral',id:341},
    {name:'Orange',id:342},
    {name:'Oreo cookie',id:343},
    {name:'Orgeat Syrup',id:344},
    {name:'Oyster Sauce',id:345},
    {name:'Papaya',id:346},
    {name:'Passion fruit syrup',id:347},
    {name:'Peach',id:348},
    {name:'Peanut Oil',id:349},
    {name:'Pepper',id:350},
    {name:'Peppermint extract',id:351},
    {name:'Pernod',id:352},
    {name:'Pineapple Syrup',id:353},
    {name:'Pineapple',id:354},
    {name:'Plain Chocolate',id:355},
    {name:'Plain Flour',id:356},
    {name:'Plums',id:357},
    {name:'Powdered Sugar',id:358},
    {name:'Raisins',id:359},
    {name:'Raspberry Jam',id:360},
    {name:'Raspberry syrup',id:361},
    {name:'Red Chile Flakes',id:362},
    {name:'Red Chili Flakes',id:363},
    {name:'Red Hot Chili Flakes',id:364},
    {name:'Rhubarb',id:365},
    {name:'Rock Salt',id:366},
    {name:'Rosemary Syrup',id:367},
    {name:'Rosemary',id:368},
    {name:'Salt',id:369},
    {name:'Salted Chocolate',id:370},
    {name:'Sherbet',id:371},
    {name:'Sirup of roses',id:372},
    {name:'Soy Sauce',id:373},
    {name:'Soya Sauce',id:374},
    {name:'Strawberries',id:375},
    {name:'Strawberry syrup',id:376},
    {name:'Sugar Syrup',id:377},
    {name:'Sugar',id:378},
    {name:'Sweet Cream',id:379},
    {name:'Tabasco Sauce',id:380},
    {name:'Thyme',id:381},
    {name:'Tomato',id:382},
    {name:'Vanilla extract',id:383},
    {name:'Vanilla Ice-Cream',id:384},
    {name:'Vanilla syrup',id:385},
    {name:'Vanilla',id:386},
    {name:'Vinegar',id:387},
    {name:'Watermelon',id:388},
    {name:'Whipped Cream',id:389},
    {name:'Whipping Cream',id:390},
    {name:'White Vinegar',id:391},
    {name:'Worcestershire Sauce',id:392},
    {name:'Wormwood',id:393},
    {name:'Yeast',id:394},
    {name:'Yoghurt',id:395},
]

export default extraAutoItems