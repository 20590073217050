const mixerAutoItems = [
    {name:'7-up',id:396},
    {name:'Agave Syrup',id:397},
    {name:'Apple Juice',id:398},
    {name:'Apricot Nectar',id:399},
    {name:'Bitter lemon',id:400},
    {name:'Blackcurrant Cordial',id:401},
    {name:'Blackcurrant Squash',id:402},
    {name:'Carbonated Soft Drink',id:403},
    {name:'Carbonated Water',id:404},
    {name:'Cherry Cola',id:405},
    {name:'Cherry Juice',id:406},
    {name:'Chocolate Milk',id:407},
    {name:'Clamato Juice',id:408},
    {name:'Club Soda',id:409},
    {name:'Coca-Cola',id:410},
    {name:'Coffee',id:411},
    {name:'Cola',id:412},
    {name:'Cold Water',id:413},
    {name:'Cranberry Juice',id:414},
    {name:'Cream Soda',id:415},
    {name:'Crystal Light',id:416},
    {name:'Diet Coke',id:417},
    {name:'Dr. Pepper',id:418},
    {name:'Elderflower cordial',id:419},
    {name:'Espresso',id:420},
    {name:'Fanta',id:421},
    {name:'Fresca',id:422},
    {name:'Fresh Lemon Juice',id:423},
    {name:'Fresh Lime Juice',id:424},
    {name:'Fruit Juice',id:425},
    {name:'Fruit Punch',id:426},
    {name:'Gatorade',id:427},
    {name:'Ginger Ale',id:428},
    {name:'Ginger Beer',id:429},
    {name:'Grape juice',id:430},
    {name:'Grape soda',id:431},
    {name:'Grapefruit Juice',id:432},
    {name:'Guava juice',id:433},
    {name:'Hawaiian punch',id:434},
    {name:'Hot Chocolate',id:435},
    {name:'Iced tea',id:436},
    {name:'Kool-Aid',id:437},
    {name:'Lemon Juice',id:438},
    {name:'Lemon soda',id:439},
    {name:'Lemon-lime soda',id:440},
    {name:'lemon-lime',id:441},
    {name:'Lemonade',id:442},
    {name:'Lime juice cordial',id:443},
    {name:'Lime Juice',id:444},
    {name:'Limeade',id:445},
    {name:'Maui',id:446},
    {name:'Milk',id:447},
    {name:'Mountain Dew',id:448},
    {name:'Orange Juice',id:449},
    {name:'Orange Soda',id:450},
    {name:'Papaya juice',id:451},
    {name:'Passion fruit juice',id:452},
    {name:'Peach juice',id:453},
    {name:'Peach Nectar',id:454},
    {name:'Pepsi Cola',id:455},
    {name:'Pineapple Juice',id:456},
    {name:'Pineapple-orange-banana juice',id:457},
    {name:'Pink lemonade',id:458},
    {name:'Pomegranate juice',id:459},
    {name:'Raspberry cordial',id:460},
    {name:'Raspberry Juice',id:461},
    {name:'Red Bull',id:462},
    {name:'Root beer',id:463},
    {name:'Roses sweetened lime juice',id:464},
    {name:'Rosewater',id:465},
    {name:'Sarsaparilla',id:466},
    {name:'Schweppes Lemon',id:467},
    {name:'Schweppes Russchian',id:468},
    {name:'Soda Water',id:469},
    {name:'Sour Mix',id:470},
    {name:'Soy Milk',id:471},
    {name:'Soya Milk',id:472},
    {name:'Sprite',id:473},
    {name:'Squeezed Orange',id:474},
    {name:'Squirt',id:475},
    {name:'Strawberry juice',id:476},
    {name:'Sunny delight',id:477},
    {name:'Surge',id:478},
    {name:'Tang',id:479},
    {name:'Tea',id:480},
    {name:'Tomato Juice',id:481},
    {name:'Tonic Water',id:482},
    {name:'Tropicana',id:483},
    {name:'Water',id:484},
    {name:'White grape juice',id:485},
]

export default mixerAutoItems