const alcoholAutoItems = [
    {name:'151 proof rum',id:1},
    {name:'Absinthe',id:2},
    {name:'Absolut citron',id:3},
    {name:'Absolut Kurant',id:4},
    {name:'Absolut Peppar',id:5},
    {name:'Absolut Vodka',id:6},
    {name:'Advocaat',id:7},
    {name:'Aejo Rum',id:8},
    {name:'Ale',id:9},
    {name:'Allspice Dram',id:10},
    {name:'Amaretto',id:11},
    {name:'Amaro Montenegro',id:12},
    {name:'Añejo Rum',id:13},
    {name:'Angostura Bitters',id:14},
    {name:'Anisette',id:15},
    {name:'Aperol',id:16},
    {name:'Apfelkorn',id:17},
    {name:'Apple Brandy',id:18},
    {name:'Apple Cider',id:19},
    {name:'Apple Schnapps',id:20},
    {name:'Applejack',id:21},
    {name:'Apricot Brandy',id:22},
    {name:'Aquavit',id:23},
    {name:'Arrack',id:24},
    {name:'Averna',id:25},
    {name:'Bacardi Limon',id:26},
    {name:'Bacardi',id:27},
    {name:'Baileys Irish Cream',id:28},
    {name:'Banana Liqueur',id:29},
    {name:'Banana Rum',id:30},
    {name:'Barenjager',id:31},
    {name:'Beer',id:32},
    {name:'Benedictine',id:33},
    {name:'Bitters',id:34},
    {name:'Black Rum',id:35},
    {name:'Black Sambuca',id:36},
    {name:'Blackberry Brandy',id:37},
    {name:'Blackberry Schnapps',id:38},
    {name:'Blackcurrant Schnapps',id:39},
    {name:'Blackstrap rum',id:40},
    {name:'Blended Scotch',id:41},
    {name:'Blended Whiskey',id:42},
    {name:'Blue Curacao',id:43},
    {name:'Blue Curacao',id:44},
    {name:'Blueberry Schnapps',id:45},
    {name:'Bourbon',id:46},
    {name:'Brandy',id:47},
    {name:'Butterscotch Schnapps',id:48},
    {name:'Cachaca',id:49},
    {name:'Calvados',id:50},
    {name:'Campari',id:51},
    {name:'Canadian Whisky',id:52},
    {name:'Chambord Raspberry Liqueur',id:53},
    {name:'Champagne',id:54},
    {name:'Chareau',id:55},
    {name:'Cherry Brandy',id:56},
    {name:'Cherry Heering',id:57},
    {name:'Cherry Liqueur',id:58},
    {name:'Chocolate Liqueur',id:59},
    {name:'Cider',id:60},
    {name:'Cinnamon Whisky',id:61},
    {name:'Citrus Vodka',id:62},
    {name:'Cocchi Americano',id:63},
    {name:'Coconut Liqueur',id:64},
    {name:'Coconut Rum',id:65},
    {name:'Coffee Brandy',id:66},
    {name:'Coffee Liqueur',id:67},
    {name:'Cognac',id:68},
    {name:'Cointreau',id:69},
    {name:'Corona',id:70},
    {name:'Cranberry Liqueur',id:71},
    {name:'Cranberry Vodka',id:72},
    {name:'Cream Sherry',id:73},
    {name:'Creme De Almond',id:74},
    {name:'Creme De Banane',id:75},
    {name:'Creme De Cacao',id:76},
    {name:'Creme De Cassis',id:77},
    {name:'Creme de Mure',id:78},
    {name:'Creme De Noyaux',id:79},
    {name:'Creme de Violette',id:80},
    {name:'Crown Royal',id:81},
    {name:'Curacao',id:82},
    {name:'Cynar',id:83},
    {name:'Daiquiri Mix',id:84},
    {name:'Dark Creme De Cacao',id:85},
    {name:'Dark Rum',id:86},
    {name:'Drambuie',id:87},
    {name:'Dry Curacao',id:88},
    {name:'Dry Vermouth',id:89},
    {name:'Dubonnet Blanc',id:90},
    {name:'Dubonnet Rouge',id:91},
    {name:'Eggnog',id:92},
    {name:'Erin Cream',id:93},
    {name:'Everclear',id:94},
    {name:'Falernum',id:95},
    {name:'Fernet-Branca',id:96},
    {name:'Firewater',id:97},
    {name:'Forbidden Fruit',id:98},
    {name:'Frangelico',id:99},
    {name:'Galliano',id:100},
    {name:'Gin',id:101},
    {name:'Godiva Liqueur',id:102},
    {name:'Gold rum',id:103},
    {name:'Gold Tequila',id:104},
    {name:'Goldschlager',id:105},
    {name:'Grain Alcohol',id:106},
    {name:'Grand Marnier',id:107},
    {name:'Green Chartreuse',id:108},
    {name:'Green Creme de Menthe',id:109},
    {name:'Green Ginger Wine',id:110},
    {name:'Guinness stout',id:111},
    {name:'Guinness',id:112},
    {name:'Hazelnut liqueur',id:113},
    {name:'Hooch',id:114},
    {name:'Hot Damn',id:115},
    {name:'Hpnotiq',id:116},
    {name:'Ilegal Joven mezcal',id:117},
    {name:'Irish cream',id:118},
    {name:'Irish Whiskey',id:119},
    {name:'Islay single malt Scotch',id:120},
    {name:'Jack Daniels',id:121},
    {name:'Jagermeister',id:122},
    {name:'Jim Beam',id:123},
    {name:'Johnnie Walker',id:124},
    {name:'Kahlua',id:125},
    {name:'Key Largo Schnapps',id:126},
    {name:'Kirschwasser',id:127},
    {name:'Kiwi liqueur',id:128},
    {name:'Kummel',id:129},
    {name:'Lager',id:130},
    {name:'Lemon vodka',id:131},
    {name:'Light Rum',id:132},
    {name:'Lillet Blanc',id:133},
    {name:'Lillet',id:134},
    {name:'Lime liqueur',id:135},
    {name:'Lime vodka',id:136},
    {name:'Madeira',id:137},
    {name:'Malibu Rum',id:138},
    {name:'Mandarine napoleon',id:139},
    {name:'Maraschino Liqueur',id:140},
    {name:'Margarita mix',id:141},
    {name:'Martini Bianco',id:142},
    {name:'Martini Extra Dry',id:143},
    {name:'Martini Rosso',id:144},
    {name:'Melon Liqueur',id:145},
    {name:'Melon Vodka',id:146},
    {name:'Mezcal',id:147},
    {name:'Midori Melon Liqueur',id:148},
    {name:'Midori',id:149},
    {name:'Nocino',id:150},
    {name:'Orange Bitters',id:151},
    {name:'Orange Curacao',id:152},
    {name:'Orange liqueur',id:153},
    {name:'Orange rum',id:154},
    {name:'Orange vodka',id:155},
    {name:'Ouzo',id:156},
    {name:'Parfait amour',id:157},
    {name:'Passoa',id:158},
    {name:'Peach Bitters',id:159},
    {name:'Peach brandy',id:160},
    {name:'Peach liqueur',id:161},
    {name:'Peach Schnapps',id:162},
    {name:'Peach Vodka',id:163},
    {name:'Peachtree schnapps',id:164},
    {name:'Peppermint Schnapps',id:165},
    {name:'Peychaud bitters',id:166},
    {name:'Pina colada mix',id:167},
    {name:'Pineapple rum',id:168},
    {name:'Pineapple vodka',id:169},
    {name:'Pisang Ambon',id:170},
    {name:'Pisco',id:171},
    {name:'Port',id:172},
    {name:'Prosecco',id:173},
    {name:'Purple passion',id:174},
    {name:'Ramazzotti',id:175},
    {name:'Raspberry Liqueur',id:176},
    {name:'Raspberry schnapps',id:177},
    {name:'Raspberry Vodka',id:178},
    {name:'Red Wine',id:179},
    {name:'Ricard',id:180},
    {name:'Root beer schnapps',id:181},
    {name:'Rose',id:182},
    {name:'Rosso Vermouth',id:183},
    {name:'Ruby Port',id:184},
    {name:'Rum',id:185},
    {name:'Rumple Minze',id:186},
    {name:'Rye Whiskey',id:187},
    {name:'Sake',id:188},
    {name:'Sambuca',id:189},
    {name:'Scotch',id:190},
    {name:'Sherry',id:191},
    {name:'Singani',id:192},
    {name:'Sloe Gin',id:193},
    {name:'Sour Apple Pucker',id:194},
    {name:'Southern Comfort',id:195},
    {name:'Spiced Rum',id:196},
    {name:'St. Germain',id:197},
    {name:'Strawberry liqueur',id:198},
    {name:'Strawberry Schnapps',id:199},
    {name:'Swedish punsch',id:200},
    {name:'Sweet and Sour',id:201},
    {name:'Sweet Vermouth',id:202},
    {name:'Tawny port',id:203},
    {name:'Tennessee whiskey',id:204},
    {name:'Tequila rose',id:205},
    {name:'Tequila',id:206},
    {name:'Tia Maria',id:207},
    {name:'Triple Sec',id:208},
    {name:'Tuaca',id:209},
    {name:'Vanilla liqueur',id:210},
    {name:'Vanilla schnapps',id:211},
    {name:'Vanilla vodka',id:212},
    {name:'Vermouth',id:213},
    {name:'Vodka',id:214},
    {name:'Watermelon schnapps',id:215},
    {name:'Whiskey',id:216},
    {name:'Whisky',id:217},
    {name:'White chocolate liqueur',id:218},
    {name:'White Creme de Menthe',id:219},
    {name:'White port',id:220},
    {name:'White Rum',id:221},
    {name:'White Vermouth',id:222},
    {name:'White Wine',id:223},
    {name:'Wild Turkey',id:224},
    {name:'Wildberry schnapps',id:225},
    {name:'Wine',id:226},
    {name:'Yellow Chartreuse',id:227},
    {name:'Yukon Jack',id:228},
    {name:'Zima',id:229},
]

export default alcoholAutoItems